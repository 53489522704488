import React from "react";
import { CaseStudyTemplate, CaseStudyTemplateProps } from "@src/components/CaseTemplete";
import * as UseCase from "@src/components/partials/districts-2025/UseCaseProvider";
import MolinePhoto from "@src/www/districts/_assets/moline.jpg";
import MolineLogoWhite from "@src/www/districts/_assets/moline@2x.png";
import MolineIcon from "@src/www/districts/_assets/icon-chart.png";
import MolineHeadshot from "@src/www/districts/_assets/moline-headshot.jpg";
import getLocale from "@src/utils/getLocale";

const MolinaCoalValley = ({ pageContext }) => {
  const c = UseCase.useGetCase(0);

  const userLanguage = getLocale(pageContext);
  const noIndex = ["en", "en-us"].includes(userLanguage) ? false : true;

  const molinaCoalValley: CaseStudyTemplateProps = {
    id: "moline",
    shouldIndex: noIndex,
    hero: {
      image: MolinePhoto,
      title: c.title,
      logo: MolineLogoWhite,
    },
    person: {
      image: MolineHeadshot,
      name: c.quote_person_name,
      title: c.quote_person_title,
      quote: c.quote,
    },
    content: c.content,
    summary: [
      {
        text: c.stat_1_text,
        value: c.stat_1_value,
      },
      {
        text: c.stat_2_text,
        value: c.stat_2_value,
        icon: MolineIcon,
      },
      {
        text: c.stat_3_text,
        value: c.stat_3_value,
      },
    ],
  };

  return <CaseStudyTemplate {...molinaCoalValley} />;
};

export default UseCase.withProvider(MolinaCoalValley);
